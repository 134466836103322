import React from 'react'
import styles from './star-rating.module.sass'

export default function StarRating({ rate }) {
  return (
    <span
      className={styles.starRating}
      style={{
        '--stars': rate,
        '--gaps': Math.ceil(rate) - 1,
        '--frames': Math.ceil(rate)
      }}
    >
      {rate}
    </span>
  )
}
