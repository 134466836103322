import React, { useRef, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import CertificateCard from '../CardItems/CertificateCard'
import Accent from '../Inlines/Accent'
import classNames from 'classnames'
import styles from './cv-certificates.module.sass'

export default function CvCertificates({ certificates }) {
  const carouselInstance = useRef(null)
  const carouselOptions = {
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    selectedAttraction: 0.1,
    friction: 0.8,
    draggable: true,
    groupCells: true,
    on: {
      ready: function() {
        this.element.classList.toggle('buttons-disabled', this.element.querySelectorAll('.flickity-button[disabled]').length > 1)
      }
      // scroll: function() {
      //   this.element.classList.toggle('buttons-disabled', this.element.querySelectorAll('.flickity-button[disabled]').length > 1)
      // }
    }
  }

  useEffect(() => {
    if (!carouselInstance.current) {
      return
    }
    const flkty = carouselInstance.current
    const resize = carouselInstance.current.resize
    flkty.resize = () => {
      flkty.element.classList.add(`flickity-resize`)
      resize.call(flkty)
      flkty.element.classList.remove(`flickity-resize`)
    }
  }, [])

  return (
    <>
      {certificates && certificates.length >= 1 && (
        <section className={styles.cvCertificates}>
          <h2 className={styles.sectionTitle}>My Certificates</h2>
          <Accent />
          <div className={styles.sectionContent}>
            <Flickity
              flickityRef={carouselRef => {
                carouselInstance.current = carouselRef
              }}
              className={classNames(styles.flickityCarousel, styles.certificateList)}
              options={carouselOptions}
            >
              {certificates.map((certificate, i) => (
                <CertificateCard key={i} {...certificate} compact={certificates.length > 6} />
              ))}
            </Flickity>
          </div>
        </section>
      )}
    </>
  )
}
