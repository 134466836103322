import React from 'react'
import Markdown from 'markdown-to-jsx'
import Button from '../Inlines/Button'
import Number from '../Inlines/Number'
import Experience from '../Inlines/Experience'
import Link from '../Link'
import styles from './cv-header.module.sass'

export default function CvHeader({ content, person }) {
  return (
    <section className={styles.cvHeader}>
      <Markdown
        className={styles.headerContent}
        options={{
          forceWrapper: true,
          overrides: {
            Button,
            Email: { component: Email, props: { name: person && person.name, className: styles.emailLink } },
            GitHub,
            LinkedIn,
            Behance,
            Intro,
            Number: { component: Number, props: { className: styles.number } },
            Experience: { component: Experience, props: { className: styles.number } }
          }
        }}
      >
        {content || `# ${person && (person.name || person.id)}`}
      </Markdown>
    </section>
  )
}

const Intro = ({ children }) => (
  <>
    {React.Children.map(children, child => {
      return (
        React.isValidElement(child) &&
        React.cloneElement(child, {
          className: 'intro ' + (child.props.className || '')
        })
      )
    })}
  </>
)

const Mailto = ({ email, subject = '', body = '', children, className }) => {
  let params = subject || body ? '?' : ''
  if (subject) params += `subject=${encodeURIComponent(subject)}`
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`

  return (
    <a href={`mailto:${email}${params}`} className={className}>
      {children}
    </a>
  )
}

const Email = ({ children, email, name, className }) => {
  return (
    <Mailto email={email} subject="[Project] for SoftKraft" body={`Hello ${name && name.split(' ')[0]},`} className={className}>
      {children || name}
    </Mailto>
  )
}

const GitHub = ({ children, ...rest }) => (
  <Link {...rest} className={styles.githubLink}>
    GitHub
  </Link>
)

const LinkedIn = ({ children, ...rest }) => (
  <Link {...rest} className={styles.linkedinLink}>
    LinekdIn
  </Link>
)

const Behance = ({ children, ...rest }) => (
  <Link {...rest} className={styles.behanceLink}>
    Behance
  </Link>
)
