import React from 'react'
import TestimonialCarousel from '../Blocks/TestimonialCarousel'
import TestimonialCard from '../CardItems/TestimonialCard'
import Accent from '../Inlines/Accent'
import styles from './cv-testimonials.module.sass'

export default function CvTestimonials({ testimonials }) {
  if (!(testimonials && testimonials.length > 0)) {
    return null
  }

  return (
    <section className={styles.cvTestimonials}>
      <h2 className={styles.sectionTitle}>
        See that people I help
        <br /> are saying about me
      </h2>
      <Accent />
      <div className={styles.sectionContent}>
        <TestimonialCarousel customClass={styles.testimonialCarousel}>
          {testimonials.map((testimonial, i) => (
            <TestimonialCard key={i} {...testimonial} />
          ))}
        </TestimonialCarousel>
      </div>
    </section>
  )
}
