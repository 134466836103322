import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import meta from '../../content/meta/default.yml'

export default function PageHead({
  title = meta.title,
  title_serp,
  subtitle,
  keywords,
  description,
  siteUrl = meta.siteUrl,
  canonical_link,
  image,
  hero,
  orig_path,
  path,
  robots = meta.robots
}) {
  const prependSiteUrl = path => (/http/.test(path) ? path : siteUrl + path)

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>
        {title_serp || title}
        {subtitle ? ` - ${subtitle}` : ''}
      </title>

      {keywords && <meta name="keywords" content={keywords} />}

      <meta name="description" content={description} />

      <meta name="robots" content={robots} />

      {meta.structuredData.map((item, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(item)}
        </script>
      ))}

      <meta name="twitter:site" content="soft_kraft" />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={prependSiteUrl(orig_path || path)} />
      <meta property="og:image" content={prependSiteUrl(get(hero, 'background') || image)} />

      <link rel="canonical" href={prependSiteUrl(canonical_link || path)} />
    </Helmet>
  )
}
