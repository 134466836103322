import React from 'react'
import { graphql } from 'gatsby'
// import PropTypes from 'prop-types'
import get from 'lodash/get'
import CvLayout from '../layout/CvLayout'
import PageHead from '../layout/PageHead'
import CvHeader from '../components/Cvs/CvHeader'
import CvAside from '../components/Cvs/CvAside'
import CvTestimonials from '../components/Cvs/CvTestimonials'
import CvProjects from '../components/Cvs/CvProjects'
import CvCertificates from '../components/Cvs/CvCertificates'
import CvPublications from '../components/Cvs/CvPublications'

import styles from '../components/Cvs/cv-article.module.sass'

export default function CV({ data: { cv, images, selectedCaseStudies, selectedPosts } }) {
  // console.log(cv.person)
  return (
    <CvLayout>
      <PageHead title={get(cv, 'person.name', 'CV')} subtitle={get(cv, 'person.headline')} hidden />
      <article className={styles.cvArticle}>
        <div className={styles.cvContent}>
          <CvHeader content={cv.header} person={cv.person} />
          <CvTestimonials testimonials={cv.testimonials} />
          <CvCertificates certificates={cv.certificates} />
          <CvProjects projects={cv.projects} selectedCaseStudies={selectedCaseStudies.nodes} />
          <CvPublications publications={cv.publications} selectedPosts={selectedPosts.nodes} />
        </div>
        <CvAside experience={cv.experience && cv.experience.json && JSON.parse(cv.experience.json)} person={cv.person} images={images} />
      </article>
    </CvLayout>
  )
}

export const query = graphql`
  query CV($path: String!, $directories: [String]!, $projectPaths: [String]!, $publicationPaths: [String]!) {
    cv: cvsYaml(path: { eq: $path }) {
      path
      header
      person {
        id
        name
        headline
        image
        profileImage
      }
      experience {
        json
      }
      testimonials {
        text
        image
        name
        position
      }
      projects {
        path
        title
        description
        image
      }
      certificates {
        title
        image
        validation
        link
        document
      }
      publications {
        path
        title
        image
      }
    }

    selectedCaseStudies: allCaseStudiesYaml(filter: { path: { in: $projectPaths } }) {
      nodes {
        path
        title
        description
        thumb
      }
    }

    selectedPosts: allPostsYaml(filter: { path: { in: $publicationPaths } }) {
      nodes {
        title
        path
        thumb
      }
    }
    images: allImageSharp(filter: { fields: { parentDir: { in: $directories } } }) {
      nodes {
        fluid(maxWidth: 540) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

// CV.propTypes = {
//   data: PropTypes.shape({
//     profile: PropTypes.shape({
//       sections: PropTypes.array
//     }).isRequired
//   }).isRequired
// }
