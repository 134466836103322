import React, { useState } from 'react'
import Image from '../Image'
import Link from '../Link'
import { getDomain } from '../utils'
import classNames from 'classnames'
import styles from './certificate-card.module.sass'
import SimpleModal from '../Modals/SimpleModal'

export default function CertificateCard({ title, image, validation, link, compact, document }) {
  const [activeModal, setActiveModal] = useState(null)

  if (link) {
    var domain = getDomain(link)
    if (domain) {
      domain = domain && domain.charAt(0).toUpperCase() + domain.slice(1)
    }
  }

  return (
    <div className={classNames(styles.certificateCard, compact ? styles.compact : styles.expanded)}>
      <figure className={styles.image}>
        <Image src={image} alt={title} />
        <figcaption>{title}</figcaption>
      </figure>
      {document && (
        <>
          <button className={styles.document} onClick={() => setActiveModal(document)}>
            See the certificate
          </button>
          <SimpleModal name={document} activeModal={activeModal} setActiveModal={setActiveModal}>
            <Image src={document} alt="" />
          </SimpleModal>
        </>
      )}

      {link && (
        <Link to={link} className={styles.validation}>
          Validation number
          <b>{validation}</b>
          <span className={styles.link}>Validate at {domain}</span>
        </Link>
      )}
    </div>
  )
}
