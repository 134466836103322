import React, { useRef, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import styles from './testimonial-carousel.module.sass'
import classNames from 'classnames'
import chunk from 'lodash/chunk'
import { isMobile } from 'react-device-detect'
import { debounce } from '../utils'

function slidesVisible(elements) {
  elements.forEach(element => {
    if (typeof element.getBoundingClientRect !== 'function') {
      return
    }
    var rect = element.getBoundingClientRect()
    var flickityElement = element.closest('.flickity-viewport, .flickity-enabled')
    if (flickityElement) {
      var viewport = flickityElement.getBoundingClientRect()
      var visible = rect.x >= viewport.x - 40 && rect.x + rect.width <= viewport.x + viewport.width + 40
      element.classList.toggle('is-inactive', !visible)
    }
  })
}

export default function TestimonialCarousel({ children, customClass }) {
  const carouselInstance = useRef(null)
  const carouselOptions = {
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    selectedAttraction: 0.2,
    friction: 0.8,
    draggable: true,
    groupCells: true,
    on: {
      ready: function() {
        slidesVisible(this.cells.map(c => c.element))
        this.element.classList.toggle('buttons-disabled', this.element.querySelectorAll('.flickity-button[disabled]').length > 1)
      },
      scroll: function() {
        slidesVisible(this.cells.map(c => c.element))
      }
    }
  }

  useEffect(() => {
    const flkty = carouselInstance.current
    const resize = carouselInstance.current.resize
    flkty.resize = () => {
      flkty.element.classList.add(`flickity-resize`)
      resize.call(flkty)
      flkty.element.classList.remove(`flickity-resize`)
    }
    const debouncedHandleResize = debounce(function handleResize() {
      flkty.resize()
    }, 300)
    window.addEventListener('DOMContentLoaded', event => debouncedHandleResize)
    window.addEventListener('resize', debouncedHandleResize)
    document.fonts && document.fonts.ready.then(debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
    // ;[...flkty.element.querySelectorAll('.flickity-button')].forEach(button => {
    //   button.addEventListener('click', () => {
    //     flkty.element.parentElement.parentElement.parentElement.scrollIntoView({
    //       behavior: 'smooth'
    //     })
    //   })
    // })
  }, [])
  return (
    <Flickity
      flickityRef={carouselRef => {
        carouselInstance.current = carouselRef
      }}
      className={classNames(styles.testimonialCarousel, customClass)}
      options={carouselOptions}
      reloadOnUpdate
    >
      {!isMobile && React.Children.count(children) >= 6
        ? chunk(React.Children.toArray(children), 2).map((group, index) => (
            <div className={styles.cellGroup} key={index}>
              {group}
            </div>
          ))
        : children}
    </Flickity>
  )
}
