import React from 'react'
import Number from '../Inlines/Number'

export default function Experience({ start, text = 'of *experience*', className }) {
  let startDate

  if (/^\d{4}$/.test(start)) {
    // If start is a year, create a Date object for January 1st of that year
    startDate = new Date(`${start}-01-01`)
  } else if (/^\d{2}\.\d{4}$/.test(start)) {
    // If start is a month and year separated by dot, create a Date object for the first day of that month
    const [month, year] = start.split('.')
    startDate = new Date(`${year}-${month}-01`)
  } else {
    console.error(`Invalid start date format: ${start}`)
    return null
  }

  const endDate = new Date()
  const yearsOfExperience = endDate.getFullYear() - startDate.getFullYear()
  const hasFullYearElapsed =
    endDate.getMonth() > startDate.getMonth() || (endDate.getMonth() === startDate.getMonth() && endDate.getDate() >= startDate.getDate())
  const fullYearsOfExperience = hasFullYearElapsed ? yearsOfExperience : yearsOfExperience - 1

  return <Number value={`**${fullYearsOfExperience}** years`} text={text} className={className} />
}
