import React, { Fragment } from 'react'

import { GAEvent, TimeTracker } from '../components/tracking'

import ContactTrackPath from '../components/ContactForm/ContactTrackPath'

import '../styles/all.sass'

const Layout = ({ children }) => {
  return (
    <Fragment>
      {children}

      <ContactTrackPath />

      <TimeTracker time={10}>
        <GAEvent category="Page" action="Read" value={10} />
      </TimeTracker>

      <TimeTracker time={30}>
        <GAEvent category="Page" action="Read" value={30} />
      </TimeTracker>
    </Fragment>
  )
}

export default Layout
