import React from 'react'
import imagePath from '../../utils/image-path'
import styles from './testimonial-card.module.sass'
import classNames from 'classnames'
import Image from '../Image'
import StarRating from '../Elements/StarRating'

export default function TestimonialCard({ text, image, name, position, className, rate, person, customClass, imageSize }) {
  rate = typeof rate !== 'undefined' ? rate : '5.0'
  return (
    <div className={classNames(className || styles.testimonialCard, customClass)}>
      <blockquote>{text}</blockquote>
      <div className={styles.author}>
        {image && <Image className={styles.authorImage} alt={name} src={imagePath(image)} width={imageSize || 64} height={imageSize || 64} wrapper />}
        <div className={styles.authorContent}>
          {name && <b className={styles.authorName}>{name}</b>}
          {position && <span className={styles.authorPosition}>{position}</span>}
          {rate && <StarRating rate={rate} />}
        </div>
      </div>
    </div>
  )
}
