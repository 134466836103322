import React from 'react'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import styles from './modal.module.sass'

export default function SimpleModal({ name, children, className, activeModal, setActiveModal }) {
  function afterOpenModal() {
    disableBodyScroll(document.querySelector('.ReactModal__Overlay'))
  }

  function closeModal() {
    enableBodyScroll(document.querySelector('.ReactModal__Overlay'))
    setActiveModal(null)
  }

  return (
    <ReactModal
      overlayClassName={styles.modalOverlay}
      className={classNames(styles.modalContent, className)}
      isOpen={activeModal === name}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="modal"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      {children}
      <button className={styles.closeButton} data-dismiss="modal" aria-label="Close" onClick={closeModal}>
        Close
      </button>
    </ReactModal>
  )
}
