import React from 'react'
import keyBy from 'lodash/keyBy'
import CaseGrid from '../Wrappers/CaseGrid'
import CaseCard from '../CardItems/CaseCard'
import Accent from '../Inlines/Accent'
import styles from './cv-projects.module.sass'

export default function CvProjects({ projects, selectedCaseStudies }) {
  if (!(projects && projects.length > 0)) {
    return null
  }
  const caseStudiesByPath = keyBy(selectedCaseStudies, 'path')
  const caseStudies = projects.map(({ path }) => caseStudiesByPath[path])

  projects = projects.map((item, i) => Object.assign({}, item, caseStudies[i]))

  return (
    <section className={styles.cvProjects}>
      <h2 className={styles.sectionTitle}>
        Take a look at projects
        <br /> I did for my customers
      </h2>
      <Accent />
      <div className={styles.sectionContent}>
        <CaseGrid>
          {projects.map((project, i) => (
            <CaseCard key={i} {...project} customClass={styles.projectCard} featured />
          ))}
        </CaseGrid>
      </div>
    </section>
  )
}
