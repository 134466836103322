import React from 'react'
import Markdown from 'markdown-to-jsx'
import styles from './number.module.sass'

export default function Number({ value = '', unit = '', text = '', className }) {
  return (
    <span className={className || styles.number}>
      <Markdown className="value">{value + unit}</Markdown> <Markdown className="text">{text}</Markdown>
    </span>
  )
}
