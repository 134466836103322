import React from 'react'
import keyBy from 'lodash/keyBy'
import PostGrid from '../Wrappers/PostGrid'
import PostCard from '../CardItems/PostCard'
import Accent from '../Inlines/Accent'
import styles from './cv-publications.module.sass'

export default function CvPublications({ publications, selectedPosts }) {
  if (!(publications && publications.length > 0)) {
    return null
  }
  const postsByPath = keyBy(selectedPosts, 'path')
  const posts = publications.map(({ path }) => postsByPath[path])

  // console.log(publications.map(item => item.path))

  publications = publications.map((item, i) => Object.assign({}, item, posts[i]))

  return (
    <section className={styles.cvPublications}>
      <h2 className={styles.sectionTitle}>My Contributions</h2>
      <Accent />
      <div className={styles.sectionContent}>
        <PostGrid>
          {publications.map((project, i) => (
            <PostCard key={i} {...project} customClass={styles.projectCard} />
          ))}
        </PostGrid>
      </div>
    </section>
  )
}
