import React from 'react'
import Image from '../Image'
import StarRating from '../Elements/StarRating'
import classNames from 'classnames'
import styles from './cv-aside.module.sass'
import { parameterize } from '../utils'

const Tech = ({ name, rate }) => (
  <li className={styles.technology}>
    <Image src={`/images/technologies/${parameterize(name)}.svg`} wrapper />

    {name.split(',').length > 1 ? (
      <ul>
        {name.split(/\s*,\s*/).map(n => (
          <li className={styles.technologyName} title={name}>
            {n}
          </li>
        ))}
      </ul>
    ) : (
      <span className={styles.technologyName} title={name}>
        {name}
      </span>
    )}

    <StarRating rate={rate} />
  </li>
)

export default function CvAside({ experience, person, images }) {
  return (
    <aside className={styles.cvAside}>
      {person && person.image && (
        <figure className={classNames(styles.asideImage, { profileImage: person.profileImage })}>
          <Image src={person.profileImage || person.image} images={images} />
        </figure>
      )}
      {experience && (
        <>
          <h2>Experience</h2>
          <ul className={styles.experience}>
            {Object.keys(experience).map((key, index) =>
              typeof experience[key] === 'number' ? (
                <Tech key={index} name={key} rate={experience[key]} />
              ) : (
                <li key={index} className={styles.group}>
                  <h3>{key}</h3>
                  <ul>
                    {Object.keys(experience[key]).map((k, i) => (
                      <Tech key={i} name={k} rate={experience[key][k]} />
                    ))}
                  </ul>
                </li>
              )
            )}
          </ul>
        </>
      )}
    </aside>
  )
}
