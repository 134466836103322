import React from 'react'
import classNames from 'classnames'

const Accent = ({ className }) => {
  return <span className={classNames('accent', className)}></span>
}

Accent.displayName = 'Accent'

export default Accent
