import React, { useEffect } from 'react'
import Image from '../Image'
import styles from './case-card.module.sass'
import classNames from 'classnames'

export default function CaseCard({ path, thumb, image, title, description, featured, background, customClass }) {
  useEffect(() => {
    document.fonts &&
      document.fonts.ready.then(function() {
        document.querySelectorAll('.hasImage .case-description:not([style])').forEach(p => (p.style.height = p.offsetHeight + 20 + 'px'))
      })
  }, [])

  return (
    <article
      className={classNames(styles.caseCard, featured ? styles.featuredCase : styles.defaultCase, customClass, { hasImage: image || thumb })}
      style={{ backgroundColor: background }}
    >
      <div className={styles.caseContent}>
        {title && <h2>{title}</h2>}
        {description && <p className="case-description">{description}</p>}
        <a href={path} target="_blank" className={styles.caseLink}>
          View case study
        </a>
      </div>
      {(thumb || image) && (
        <figure className={styles.caseImage}>
          <Image src={thumb || image} alt={title} />
        </figure>
      )}
    </article>
  )
}
